export default {
  data() {
    return {
      bookingConsent: true,
      bookingExtensionInitialized: false,
    };
  },

  methods: {
    createBookingUrl() {
      const url = new URL("https://www.booking.com/searchresults.html");
      const params = {
        aid: window.gon.booking_aid,
        label: "search-extension-fallback",
        checkin: this.checkin,
        checkout: this.checkout,
        group_adults: this.adults,
      };

      if (window.gon.iata) {
        params.iata = window.gon.iata;
        params.iata_orr = "1";
      } else if (window.gon.dst_fallback) {
        params.ss = window.gon.dst_fallback;
      }

      Object.entries(params).forEach(([key, value]) => {
        if (value) url.searchParams.set(key, value);
      });

      return url;
    },

    createSearchUrl() {
      const url = new URL("/recherche", window.location.origin);
      const form = document.getElementById("search-form");
      const formData = new FormData(form);

      formData.forEach((value, key) => {
        if (value) url.searchParams.set(key, value);
      });

      url.hash = "search-results";
      return url;
    },

    setupBookingExtension() {
      this.updateFormTarget();

      // Évite les appels multiples
      if (this.bookingExtensionInitialized) {
        return;
      }

      // Configuration de l'event listener une seule fois
      const searchButton = document.getElementById("search-submit");
      if (searchButton) {
        searchButton.addEventListener("click", this.handleSearchClick);
      }

      // Configuration de bookingLeaveBehind une seule fois
      if (window.bookingLeaveBehind) {
        window.bookingLeaveBehind.on(
          "click",
          "#search-submit",
          this.createBookingUrl(),
          {
            aid: window.gon.booking_aid,
            hostname: "sp.booking.com",
            label: "search-extension",
            iata: window.gon.iata,
            checkin: '#search-form input[name="search[checkin]"]',
            checkout: '#search-form input[name="search[checkout]"]',
            consent: "#booking-consent",
            group_adults: '#search-form input[name="search[adults]"]',
          }
        );
      }

      this.bookingExtensionInitialized = true;
    },

    updateFormTarget() {
      const form = document.getElementById("search-form");
      if (!form) return;

      if (!this.bookingConsent || !this.hasValidDestination()) {
        form.removeAttribute("target");
      } else {
        form.setAttribute("target", "_blank");
      }
    },

    handleSearchClick(event) {
      if (this.bookingConsent && this.hasValidDestination()) {
        event.preventDefault();
        window.open(this.createSearchUrl().toString());
      }
    },

    hasValidDestination() {
      return window.gon?.iata || window.gon?.dst_fallback;
    },
  },

  watch: {
    bookingConsent: {
      handler() {
        this.updateFormTarget();
      },
      immediate: true,
    },
  },

  mounted() {
    this.setupBookingExtension();
  },

  beforeUnmount() {
    const searchButton = document.querySelector("#search-submit");
    if (searchButton) {
      searchButton.removeEventListener("click", this.handleSearchClick);
    }
  },
};
